import React from "react"
import Grid from "@material-ui/core/Grid"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import Title from "../title"
import Button from "../button"
import "./index.scss"

const SubscriptionSection = ({ data }) => {
  const { content, fieldGroupName, title } = data
  // console.log(data)
  return (
    <section className="subscription-section">
      <div className="container">
        <div className="subscription-section__top">
          <Title title2={title || fieldGroupName} />
          <p className="subscription-section__top-desc">{content}</p>
        </div>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} md={5} lg={6}>
            <div data-aos="fade-right" className="subscription-section__left">
              <div>
                <h3>FREE VERSION</h3>
                <ul className="subscription-section__left-items">
                  <li>
                    <span>
                      <CheckCircleIcon />
                    </span>
                    Key Feature #1
                  </li>
                  <li>
                    <span>
                      <CheckCircleIcon />
                    </span>
                    Key Feature #2
                  </li>
                </ul>
                <Button styles="btn btn__light">Start for free</Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={6}>
            <div
              data-aos-delay="400"
              data-aos="fade-left"
              className="subscription-section__right"
            >
              <div className="subscription-section__right-title">
                <h3>BETTER FOR TRAINERS </h3>
              </div>
              <Grid container justify="center" alignItems="center">
                <div className="subscription-section__right__content">
                  <Grid item md>
                    <div className="subscription-section__right__content-item">
                      <h1>
                        <span>£49</span>/mo.
                      </h1>
                      <ul>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          Key Feature #1
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          Key Feature #2
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          Key Feature #3
                        </li>
                      </ul>
                      <Button styles="btn btn__dark">choose Plan</Button>
                    </div>
                  </Grid>
                  <Grid item md>
                    <div className="subscription-section__right__content-item">
                      <h1>
                        <span>£99</span>/mo.
                      </h1>
                      <ul>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          All from previous +
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          Key Feature #4
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          Key Feature #5
                        </li>
                      </ul>
                      <Button styles="btn btn__dark">choose Plan</Button>
                    </div>
                  </Grid>
                </div>
                <p>Want to find out more? Contact us below</p>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

export default SubscriptionSection
