import React from "react"
import Img from "gatsby-image"
import "./index.scss"

const Card = ({ iconSource, title, desc, imgAlt, cardReverse }) => {
  return (
    <div className="single-card">
      <div className="single-card__side single-card__side--front">
        <div className="single-card__side__icon">
          <Img fixed={iconSource} alt={imgAlt || "icon"} />
        </div>
        <div className="single-card__side__body">
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>
      </div>
      <div className="single-card__side single-card__side--back">
        <div className="single-card__side__icon">
          <Img
            fixed={cardReverse.image.localFile.childImageSharp.fixed}
            alt={cardReverse.image.altText || "icon"}
          />
        </div>
        <div className="single-card__side__body">
          <h3>{cardReverse.title}</h3>
          <p>{cardReverse.content}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
