import React from "react"
import Grid from "@material-ui/core/Grid"
import Card from "./card"
// import CardIcon from "../icons/card-icon"
import "./index.scss"

const CardsSection = ({ data }) => {
  const {
    content,
    fieldGroupName,
    title,
    subtitle,
    card1,
    card2,
    card3,
    card4,
    card1Reverse,
    card2Reverse,
    card3Reverse,
    card4Reverse,
  } = data

  return (
    <section className="card-section-wrapper">
      <div className="card-section-bg">
        <div className="card-section-bg__color"></div>
        <div className="card-section-bg__shape-top"></div>
        <div className="card-section-bg__shape-bottom"></div>
      </div>
      <div className="card-section">
        <div className=" container">
          <div className="card-section__title">
            <span>{subtitle}</span>
            <h1>{title || fieldGroupName} </h1>
          </div>
          <p className="card-section__desc">{content}</p>
          <div className="card-section__cards">
            <Grid container>
              <Grid
                data-aos="fade-up-left"
                data-aos-duration="1500"
                item
                sm={6}
                md
              >
                <Card
                  iconSource={card1.image.localFile.childImageSharp.fixed}
                  title={card1.title}
                  desc={card1.content}
                  imgAlt={card1.image.altText}
                  cardReverse={card1Reverse}
                />
              </Grid>
              <Grid
                data-aos="fade-up-left"
                data-aos-duration="1500"
                data-aos-delay="400"
                item
                sm={6}
                md
              >
                <Card
                  iconSource={card2.image.localFile.childImageSharp.fixed}
                  title={card2.title}
                  desc={card2.content}
                  imgAlt={card2.image.altText}
                  cardReverse={card2Reverse}
                />
              </Grid>
              <Grid
                data-aos="fade-up-left"
                data-aos-duration="1500"
                data-aos-delay="800"
                item
                sm={6}
                md
              >
                <Card
                  iconSource={card3.image.localFile.childImageSharp.fixed}
                  title={card3.title}
                  desc={card3.content}
                  imgAlt={card3.image.altText}
                  cardReverse={card3Reverse}
                />
              </Grid>
              <Grid
                data-aos="fade-up-left"
                data-aos-duration="1500"
                data-aos-delay="1200"
                item
                sm={6}
                md
              >
                <Card
                  iconSource={card4.image.localFile.childImageSharp.fixed}
                  title={card4.title}
                  desc={card4.content}
                  imgAlt={card4.image.altText}
                  cardReverse={card4Reverse}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardsSection
